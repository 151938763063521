<template>
  <component :is="questionData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert
        variant="danger"
        :show="questionData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching question data
      </h4>
      <div class="alert-body">
        No questions found with this question id. Check
        <b-link
            class="alert-link"
            :to="{ name: 'dashboard-questions-list'}"
        >
          Questions List
        </b-link>
        for other questions.
      </div>
    </b-alert>
    <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
    >
      <b-form @submit.prevent="createQuestion">
        <b-overlay
            :show="uploadingImage"
            no-wrap
        />
        <b-row>
          <b-col md="12">
            <!-- En Title -->
            <validation-provider
                #default="{ errors }"
                name="English Title"
                rules="required"
            >
              <b-form-group
                  label="English Title"
                  label-for="title-en"
              >
                <ckeditor :editor="editor" :state="errors.length > 0 ? false:null" style="height: 500px" id="title-en" rows="20" v-model="questionData.title_en"></ckeditor>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <!-- Ar Title -->
              <b-form-group
                  label="Arabic Title"
                  label-for="title-ar"
              >
                <b-form-input
                    id="title-ar"
                    v-model="questionData.title_ar"
                    trim
                />
              </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <!-- Question Image -->
            <b-form-group
                label="Question Image"
                label-for="question-image"
            >
              <file
                  title="Question Image"
                  @file_uploaded="({media})=>{questionData.image = media.url;questionData.image_id = media.id}"
                  :key="rerender_component1"
                  :default_place_holder_src="questionData.image"
              ></file>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-for="(answer,index) in questionData.answers" :key="index">
          <!-- Answers -->
          <b-col md="6">
            <validation-provider
                #default="{errors}"
                :name="'English Answer #'+(index+1)"
                rules="required"
            >
              <b-form-group
                  :label="'English Answer #'+(index+1)"
                  :label-for="'title-en'+index"
              >
                <b-form-input
                    :id="'title-en'+index"
                    v-model="answer.answer_en"
                    :state="errors.length > 0 ? false:null"
                    trim
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <b-form-group
                :label="'Arabic Answer #'+(index+1)"
                :label-for="'title-ar'+index"
            >
              <b-form-input
                  :id="'title-ar'+index"
                  v-model="answer.answer_ar"
                  trim
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
                label="Is Correct Answer"
                label-for="landmark"
            >
              <validation-provider
                  #default="{ errors }"
                  :name="'Is Correct Answer #'+(index+1)"
                  rules="required"
              >
                <b-form-radio
                    v-model="answer.is_correct"
                    class="custom-control-primary"
                    :name="'is_correct'+index"
                    value="1"
                    @change="answerChanged(index,1)"
                >
                  Yes
                </b-form-radio>
                <b-form-radio
                    v-model="answer.is_correct"
                    class="custom-control-primary"
                    :name="'is_correct'+index"
                    value="0"
                    @change="answerChanged(index,0)"
                >
                  No
                </b-form-radio>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                style="margin-top: 10px"
                class="mt-0 mt-md-2"
                @click="removeAnswer(index)"
            >
              <feather-icon
                  icon="XIcon"
                  class="mr-25"
              />
              <span>Delete Answer</span>
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col
              lg="12"
              md="12"
              class="mb-50"
          >
            <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="primary"
                style="margin-top: 10px"
                type="button"
                class="mt-0 mt-md-2"
                @click="addAnswer"
            >
              <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
              />
              <span>Add Answer</span>
            </b-button>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12">
            <!-- English explanation -->
            <b-form-group
                label="English Explanation"
                label-for="english-explanation"
            >
              <ckeditor :editor="editor" :config="editorConfig" style="height: 500px" :id="'en'" rows="20" v-model="questionData.explanation_en"></ckeditor>
            </b-form-group>
          </b-col>
          <b-col md="12" style="margin-top: 70px">
            <!-- Arabic explanation -->
            <b-form-group
                label="Arabic Explanation"
                label-for="arabic-explanation"
            >
              <ckeditor :editor="editor" :config="editorConfig" style="height: 200px" :id="'ar'" rows="10" v-model="questionData.explanation_ar"></ckeditor>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row  style="margin-top: 70px">
          <b-col md="6">
            <b-form-group
                label="Choose Explanation Audio Type"
                label-for="landmark"
            >
              <b-form-radio
                  v-model="questionData.voice_type"
                  class="custom-control-primary"
                  :name="'explanation_audio_'"
                  value="1"
              >
                Upload Mp3 File
              </b-form-radio>
              <b-form-radio
                  v-model="questionData.voice_type"
                  class="custom-control-primary"
                  :name="'explanation_audio_'"
                  value="2"
              >
                Record Voice
              </b-form-radio>
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="questionData.voice_type == 1">
            <!-- Explanation Voice -->
            <b-form-group
                label="Select Audio File"
                label-for="mc-is-active"
            >
              <b-form-file
                  v-model="questionData.explanation_voice"
                  :id="'audio'"
                  accept=".mp3,audio/*"
                  @change="uploadExplanationAudio($event)"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="questionData.voice_type == 2">
            <b-form-group
                label="Record Audio"
                label-for="mc-is-active"
            >
              <audio-recorder
                  upload-url="notes/upload-pdf"
                  :attempts="1"
                  :time="2"
                  :before-recording="callback"
                  :pause-recording="recordingPaused"
                  :after-recording="afterRecording"
                  :select-record="callback"
                  :before-upload="callback"
                  :successful-upload="successfulUpload"
                  :failed-upload="failedUpload"/>
            </b-form-group>
            <b-overlay
                :show="recordShow"
                no-wrap
            />
          </b-col>
          <b-col md="6">
            <!-- Explanation Image -->
            <b-form-group
                label="Explanation Image"
                label-for="exp-image"
            >
              <file
                  title="Select Explanation Image"
                  @file_uploaded="({media})=>{questionData.explanation_image = media.url; questionData.explanation_image_id = media.id}"
                  :key="rerender_component"
                  :default_place_holder_src="questionData.explanation_image"
              ></file>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <!-- Course -->
            <validation-provider
                #default="{errors}"
                name="Choose Course"
                rules="required"
            >
              <b-form-group
                  label="Choose Course"
                  label-for="course"
                  :state="errors.length > 0 ? false:null"
              >
                <v-select
                    v-model="questionData.course"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="courseOptions"
                    @input="val => updateCourseID(val)"
                    :reduce="val => val.value"
                    :clearable="false"
                    :state="errors.length > 0 ? false:null"
                    input-id="user-role"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <!-- Category -->
            <validation-provider
                v-if="categoriesOptions.length > 0"
                #default="{errors}"
                name="Choose Category"
                rules="required"
            >
              <b-form-group
                  label="Choose Category"
                  label-for="category"
                  :state="errors.length > 0 ? false:null"
              >
                <v-select
                    v-model="questionData.category"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="categoriesOptions"
                    :reduce="val => val.value"
                    @input="val => updateCategoryId(val)"
                    :clearable="false"
                    input-id="user-role"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6" v-if="subCategoryOptions.length > 0">
            <b-form-group
                label="Select Sub Category"
                label-for="mc-is-active"
            >
                <v-select
                    v-model="questionData.sub_category_id"
                    :options="subCategoryOptions"
                    :reduce="val => val.value"
                />

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <validation-provider
                #default="{errors}"
                name="Is Free Content"
                rules="required"
            >
              <b-form-group
                  label="Is Free Content"
                  label-for="landmark"
              >
                <b-form-radio
                    v-model="questionData.is_free_content"
                    class="custom-control-primary"
                    :name="'is_free_content_lectures'"
                    :state="errors.length > 0 ? false:null"
                    value="1"
                >
                  Yes
                </b-form-radio>
                <b-form-radio
                    v-model="questionData.is_free_content"
                    class="custom-control-primary"
                    :name="'is_free_content_lectures'"
                    :state="errors.length > 0 ? false:null"
                    value="0"
                >
                  No
                </b-form-radio>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>


        <b-row>
          <b-col>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                :disabled="disabled"
            >
              Submit
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </component>
</template>

<script>

import {ref, onUnmounted} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import questionStoreModule from '../questionStoreModule'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {BAlert, BButton, BCard, BCol, BForm, BFormInvalidFeedback,BFormCheckbox, BFormGroup,
  BFormFile, BFormInput, BLink, BRow,BFormTextarea,BFormRadio,BOverlay} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {useToast} from "vue-toastification/composition";
import { required, alphaNum, email,url } from '@validations'
import vSelect from 'vue-select'
import formValidation from "@core/comp-functions/forms/form-validation";
import Vueditor from 'vueditor'
// eslint-disable-next-line
// eslint-disable-next-line
import Vue from 'vue'
// eslint-disable-next-line
import {AudioRecorder} from 'vue-audio-recorder-no-eventbus'
import ClassicEditor from '../../../../ckeditor5-custom';
// import { ClassicEditor } from '@ckeditor/ckeditor5-upload'
import * as UploadAdapter from '../../../../src/UploadAdapter';

export default {
  components: {
    BCard,
    AudioRecorder,
    // ckeditor,
    ClassicEditor,
    Vueditor,
    BOverlay,
    BFormRadio,
    BFormInvalidFeedback,
    BFormFile,
    BFormTextarea,
    BAlert,
    BLink,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    vSelect,

    //Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  created() {
    UploadAdapter.eventBus.$on('startedUploading', (data) => {
      this.uploadingImage = true
    })
    UploadAdapter.eventBus.$on('endedUploading', (data) => {
      this.uploadingImage = false
    })
    this.$http.get('/all-courses')
        .then(response => {
          let that = this
          this.categoriesOptions.splice(0)
          this.lists.splice(0)
          this.lists = response.data.data.map(function(item){
            that.courseOptions.push({
              label : item.title_en,
              value: item.id+""
            })
          })
        })
    let config = {
      toolbar: [
        'removeFormat', 'undo', '|', 'elements', 'fontName', 'fontSize', 'foreColor', 'backColor'
      ],
      fontName: [
        {val: 'arial black'},
        {val: 'times new roman'},
        {val: 'Courier New'}
      ],
      fontSize: ['12px', '14px', '16px', '18px', '0.8rem', '1.0rem', '1.2rem', '1.5rem', '2.0rem'],
      uploadUrl: ''
    };
  },
  methods:{
    uploader(editor)
    {
      editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
        return new UploadAdapter.default( loader );
      };
    },
    addAnswer(){
      this.questionData.answers.push(
          {
            answer_en: null,
            answer_ar: null,
            is_correct: null
          },
      )
    },
    removeAnswer(answer_index){
      this.questionData.answers.splice(answer_index, 1)
    },
    answerChanged(answerIndex,value){
      this.questionData.answers.forEach(radio => {
        if (value == "1"){
          radio.is_correct = "0"
          this.questionData.answers[answerIndex].is_correct = "1"
        }else{
          radio.is_correct = "1"
          this.questionData.answers[answerIndex].is_correct = "0"
        }
      })
    },
    callback (data) {
      console.log(data)
      console.debug(data)
    },
    recordingPaused(data){
      console.log('finished' + data)
    },
    afterRecording(data){
      this.recordShow = true
      let form = new FormData();
      this.show = true
      form.append('name', 'my-pdf');
      form.append('file', data.blob);

      let config = {
        header: {
          'Content-Type': 'audio/mp3'
        }
      }
      this.$http.post('notes/upload-pdf',
          form,
          config
      ).then(response => {
        this.questionData.explanation_voice = response.data.path
        this.show = false
        this.recordShow = false
      })
      // console.log(data.blob)
    },
    successfulUpload(data){
      console.log('succ')
    },
    failedUpload(data){
      console.log('failed')
    },
    showToast(variant, position, message) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: message,
              variant,
            },
          },
          {
            position,
          })
    },
    createQuestion(){
      this.disabled = true;
      return new Promise((resolve, reject) => {
            this.$refs.refFormObserver.validate().then(success => {
              if (success) {
                store.dispatch('questions/addQuestion', this.questionData)
                    .then(() => {
                      this.showToast('success', 'top-center', "Question created successfully")
                      this.$router.push({name : 'dashboard-questions-list'})
                    })
              }else{
                this.disabled = false;
                reject()
              }
            })
      })
    },
    updateCategoryId(category_id){
      this.questionData.category_id = category_id
      this.$http.get('/sub-categories?'+'course_id='+this.questionData.course_id+'&category_id='+category_id).then(res => {
        let that = this
        this.lists.splice(0)
        this.lists = res.data.data.map(function(item){
          that.subCategoryOptions.push({
            label : item.label,
            value: item.value+""
          })
        })
        this.lists.splice(0)
      }).catch(e => {
        // reject()
      })
    },
    updateCourseID(course_id){
      this.questionData.course_id = course_id
      this.$http.get('/categories?course_id='+course_id)
          .then(response => {
            let that = this
            this.categoriesOptions.splice(0)
            this.lists.splice(0)
            this.lists = response.data.data.map(function(item){
              that.categoriesOptions.push({
                label : item.label,
                value: item.value+""
              })
            })
          })
    },
    setImage(imageId) {
      this.questionData.video_thumb = imageId
    },
    changeValue(){
      let val = this.questionData.video_type
      this.questionData.video_type = val
    },
    uploadExplanationAudio(event){
      this.recordShow = true
      let data = new FormData();
      data.append('name', 'my-audio');
      data.append('file', event.target.files[0]);

      let config = {
        header: {
          'Content-Type': 'image/png'
        }
      }
      this.$http.post('notes/upload-pdf',
          data,
          config
      ).then(response => {
        this.questionData.explanation_voice = response.data.path
        this.recordShow = false
      })
    },
  },
  data(){
    return{
      disabled: false,
      uploadingImage: false,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent' ,'|','insertTable', '|', 'imageUpload', 'mediaEmbed', '|', 'undo', 'redo','|','alignment', 'code', 'codeBlock', 'findAndReplace', 'fontColor', 'fontBackgroundColor', 'fontSize','fontFamily', 'highlight', 'htmlEmbed', 'removeFormat', 'underline' ],
        table: {
          toolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
        },
        extraPlugins: [ function(editor) {editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
          return new UploadAdapter.default(loader);
        }}],
        language: 'nl',
      },
      recordShow : false,
      required,
      url,
      courseOptions: [],
      rerender_component: 0,
      rerender_component1: 0,
      show: false,
      lists: [],
      categoriesOptions: [],
      subCategoryOptions: [],
      video_type: null,
    }
  },
  setup() {
    const blankQuestionData = {
      title_en: '',
      title_ar: '',
      description_en: '',
      description_ar: '',
      explanation_image_id: null,
      image_id: null,
      explanation_image: '',
      explanation_voice: null,
      explanation_en: '',
      explanation_ar: '',
      course_id: '',
      category_id: '',
      sub_category_id: '',
      answers: [
        {
          answer_en: null,
          answer_ar: '',
          is_correct: null
        },
        {
          answer_en: null,
          answer_ar: '',
          is_correct: null
        },
        {
          answer_en: null,
          answer_ar: null,
          is_correct: null
        },
        {
          answer_en: null,
          answer_ar: null,
          is_correct: null
        },
        {
          answer_en: null,
          answer_ar: null,
          is_correct: null
        },
      ],
      is_free_content: 0,
      image: null,
      file: null
    }
    const lectureImageId = ref(null);
    const toast = useToast()

    const questionData = ref(JSON.parse(JSON.stringify(blankQuestionData)))
    const resetQuestionData = () => {
      questionData.value = JSON.parse(JSON.stringify(blankQuestionData))
    }

    const snowOption = ref({
      theme: 'snow',
    })

    const EVENT_APP_STORE_MODULE_NAME = 'questions'

    // Register module
    if (!store.hasModule(EVENT_APP_STORE_MODULE_NAME)) store.registerModule(EVENT_APP_STORE_MODULE_NAME, questionStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVENT_APP_STORE_MODULE_NAME)) store.unregisterModule(EVENT_APP_STORE_MODULE_NAME)
    })

    const onUpdate = () => {
      let data = {
        title_en: questionData.value.title_en,
        title_ar: questionData.value.title_ar,
        is_free_content: questionData.value.is_free_content,
        url: questionData.value.path,
        course_id: questionData.value.course_id,
        category_id: questionData.value.category_id,
        sub_category_id: questionData.value.sub_category_id,

        explanation_en: questionData.value.explanation_en,
        explanation_ar: questionData.value.explanation_ar,
        explanation_voice: questionData.value.explanation_voice,
        explanation_image_id: questionData.value.explanation_image_id,
        image_id: questionData.value.image_id,
        answers: [
          {
            answer_en: null,
            answer_ar: null,
            is_correct: null
          },
          {
            answer_en: null,
            answer_ar: null,
            is_correct: null
          },
          {
            answer_en: null,
            answer_ar: null,
            is_correct: null
          },
          {
            answer_en: null,
            answer_ar: null,
            is_correct: null
          },
          {
            answer_en: null,
            answer_ar: null,
            is_correct: null
          },
        ]
      }

      store.dispatch('questions/updateQuestion', {
        id: router.currentRoute.params.id,
        data: data
      })
          .then(response => {
            router.push({name : 'dashboard-questions-list'})
            toast({
              component: ToastificationContent,
              props: {
                title: 'Question Updated Successfully',
                icon: 'EditIcon',
                position: 'top-center',
                variant: 'success',
              },
            }, {
              position: 'top-center'
            })
          })
          .catch(error => {

          })
    }
    const {
      getValidationState,
      resetForm,
      resetObserver
    } = formValidation(resetQuestionData)
    return {
      onUpdate,
      questionData,
      lectureImageId,
      getValidationState,
      resetForm,
      resetObserver,
      snowOption
    }
  },
}
</script>

<style lang="scss">

.ar-icon{
  svg{
    margin-top: -10px;
  }
}
.ar-content{
  height: 276px;
}
.ck{
  height: 200px;
}
.ar-player-bar{
  width: 64%!important;
}
.ar-player-actions{
  width: 10%!important;
}
.ar{
  width: 100%!important;
}
.ar-recorder{
  margin-left: -6%;
}
.ar-recorder__duration{
  margin-top: 28px !important;
}
</style>
